import { createSlice } from "@reduxjs/toolkit";
// import { api } from "../../../api/api";
// import {
//   extraReducers,
//   fulfilled,
//   pending,
//   rejected,
// } from "../../../utils/sharedExtraReducers";
// import { changeUpdatingHumTempStatus } from "../../updatingSlice";

const initialState = {
  getTempAndHumidData: {
    tempAndHumidData: null,
    samples: null,
    status: null,
    error: null,
  },
};

// export const tempAndHumidForUserThunk = createAsyncThunk(
//   "tempAndHumidForUser/getTempAndHumidData",
//   async function (payload, { rejectWithValue, dispatch }) {
//     const { cycleId, data, isUpdating } = payload;
//     try {
//       if (isUpdating) dispatch(changeUpdatingHumTempStatus("started"));
//       const response = await api.dashboard.getTempAndHumidData(cycleId, data);
//       dispatch(addTempAndHumidDataToState(response.data));
//       if (isUpdating) dispatch(changeUpdatingHumTempStatus("finished"));
//     } catch (error) {
//       if (isUpdating) dispatch(changeUpdatingHumTempStatus("finished"));
//       return rejectWithValue(error.message);
//     }
//   }
// );

const tempAndHumidForUserSlice = createSlice({
  name: "tempAndHumidForUser",
  initialState,
  reducers: {
    // addTempAndHumidDataToState(state, action) {
    //   if (!action.payload) {
    //     state.getTempAndHumidData.tempAndHumidData = action.payload;
    //     state.getTempAndHumidData.samples = action.payload;
    //   } else {
    //     const { samples, ...tempAndHumidData } = action.payload;

    //     const normalizedSamples = samples.reduce(
    //       (accum, item) => {
    //         if (accum.temp[item.position]) {
    //           if(item.field === "humidity") {
    //             accum.humid[item.position].push([
    //               item.time,
    //               item.value,
    //             ]);
    //           }
    //           if(item.field === "temperature") {
    //             accum.temp[item.position].push([
    //               item.time,
    //               item.value,
    //             ]);
    //           }
    //         }
    //         return accum;
    //       },
    //       {
    //         temp: { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] },
    //         humid: { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] },
    //       }
    //     );
    //     state.getTempAndHumidData.tempAndHumidData = tempAndHumidData;
    //     state.getTempAndHumidData.samples = normalizedSamples;
    //   }
    // },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(
  //       tempAndHumidForUserThunk.pending,
  //       extraReducers(pending, "getTempAndHumidData")
  //     )
  //     .addCase(
  //       tempAndHumidForUserThunk.rejected,
  //       extraReducers(rejected, "getTempAndHumidData")
  //     )
  //     .addCase(
  //       tempAndHumidForUserThunk.fulfilled,
  //       extraReducers(fulfilled, "getTempAndHumidData")
  //     );
  // },
});

export const { addTempAndHumidDataToState } = tempAndHumidForUserSlice.actions;
export default tempAndHumidForUserSlice.reducer;
