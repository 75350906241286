import Header from "../components/header/Header";
import Menu from "../components/menu/Menu";
import ModalCreateFarm from "../components/modal/ModalCreateFarm/ModalCreateFarm";
import "./style.scss";
import ModalCreateCoop from "../components/modal/ModalCreateCoop/ModalCreateCoop";
import ModalCreateUser from "../components/modal/ModalCreateUser/ModalCreateUser";
import ModalCreateCompanys from "../components/modal/ModalCreateCompanys/ModalCreateCompanys";
import ModalAttachUserCoop from "../components/modal/ModalAttachUserCoop/ModalAttachUserCoop";
import ModalAttachUserFarm from "../components/modal/ModalAttachUserFarm/ModalAttachUserFarm";
import ModalEditUser from "../components/modal/ModalEditUser/ModalEditUser";
import ModalEditCompanys from "../components/modal/ModalEditCompanys/ModalEditCompanys";

function LayoutDefault({component, headerBar}) {
  
  return (
    <div className="container">
      <Header headerBar={headerBar}/>
      <div className="content">
        <Menu />
        <div className="row">
          {component}
        </div>
      </div>
      <ModalCreateFarm/>
      <ModalCreateCoop/>
      <ModalCreateUser/>
      <ModalCreateCompanys/>
      <ModalAttachUserCoop />
      <ModalAttachUserFarm />
      <ModalEditUser />
      <ModalEditCompanys />
    </div>
  );
}

export default LayoutDefault;
