import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/loginPage/LoginPage";
import MainPage from "../pages/mainPage/MainPage";
import LayoutDefault from "../layout/LayoutDefault";
import DashboardHeader from "../components/header/pages/dashboard/DahshboardHeader";
import Data from "../pages/data/Data";
import Calculator from "../pages/calculator/Calculator";
import Report from "../pages/report/Report";
import TableCoops from "../pages/report/tableCoops/TableCoops";
import UsersPage from "../pages/settings/users/Users";
import DefaultHeader from "../components/header/pages/default/DefaultHeader";
import SettingUserHeader from "../components/header/pages/settingUser/SettingUserHeader";
import CompanyPage from "../pages/settings/company/Company";
import SettingCompanyHeader from "../components/header/pages/settingCompany/SettingCompanyHeader";
/* import { getProfile } from "../../store/authActions"; */

const appRoutes = [
  {
    id: 1,
    path: "/",
    element: <LoginPage />,
    availableFor: [null, "user", "admin"],
  },
  {
    id: 2,
    path: "/dashboard",
    element: <MainPage />,
    headerBar: <DashboardHeader />,
    availableFor: ["user", "admin"],
  },
  {
    id: 3,
    path: "/data",
    element: <Data />,
    headerBar: <DashboardHeader />,
    availableFor: ["user", "admin"],
  },
  {
    id: 4,
    path: "/calculator",
    element: <Calculator />,
    headerBar: <DashboardHeader />,
    availableFor: ["user", "admin"],
  },
  
  {
    id: 5,
    path: "/report",
    element: <Report />,
    headerBar: <DashboardHeader />,
    availableFor: ["user", "admin"],
  },

  {
    id: 6,
    path: "/report/:id/coops",
    element: <TableCoops/>,
    headerBar: <DashboardHeader />,
    availableFor: ["user", "admin"],
  },

  {
    id: 7,
    // path: "/settings/user",
    path: "/users",
    element: <UsersPage/>,
    headerBar: <SettingUserHeader />,
    availableFor: ["user", "admin"],
  },
  {
    id: 8,
    // path: "/settings/company",
    path: "/company",
    element: <CompanyPage/>,
    headerBar: <SettingCompanyHeader />,
    availableFor: ["user", "admin"],
  }
];

function AppRoutes() {
  /*   const dispatch = useDispatch(); */
  /*   const isUserAuth = useSelector((state) => state.auth.authData.accessToken);
  const userRole  = useSelector(state => state.auth.profileData.profile.role) */
  /*   const navigate = useNavigate(); */

  /* useEffect( () => {
  if(!isUserAuth) {
    navigate('/')
  }
}, [isUserAuth, navigate])



useEffect( () => {
  if(isUserAuth) {
    dispatch(getProfile())
  } 
}, [isUserAuth, dispatch]) */

  const availableRoutes = () =>
    appRoutes.filter(
      (route) =>
        route.availableFor.includes(null) ||
        route.availableFor.includes("admin")
    );

  return (
    <>
      <Routes>
        {availableRoutes().map((route) => (
          route.path === '/' ? 
            <Route key={route.id} path={route.path} element={route.element} />:
            <Route key={route.id} path={route.path} element={<LayoutDefault component={route.element} headerBar={route.headerBar} />} />
        ))}
      </Routes>
    </>
  );
}

export default AppRoutes;
