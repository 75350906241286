import classNames from "classnames";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createErrorThunk, setError } from "../../store/notification";


function Notification({contentModal, setShow}) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification.notification);

  return (
    <>
        <div className={styles.notifRow}>
            {
            notifications.map((notification) => (
                <div key={notification.key} className={classNames(styles.notifItem, {
                    [styles.error]: notification.type === "error", 
                    [styles.info]: notification.type === "info" 
                })}>
                    {notification.title}
                </div>
            ))}

        </div>
    </>
  );
}

export default Notification;
