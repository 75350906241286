import classNames from "classnames";
import style from "./style.module.scss";

function Checkbox({className,
    disabled,
    inputStyle,
    name,
    onChange,
    readOnly,
    value,
    placeholder,
    id="checkbox"
    }) {


    return (
        <div className={classNames(style.input, className)}>
            <input
            aria-label={name}
            data-testid={name}
            tabIndex={0}
            type="checkbox"
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            style={inputStyle}
            disabled={disabled}
            readOnly={readOnly}
            id={id}
            />
            <label htmlFor={id}></label>   
        </div>
    );
}

export default Checkbox;
