import { useEffect, useState } from "react";
import styles from "./ModalCreateCompanys.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { changeUpdatingReloadStatus } from "../../../store/reloadMarker";
import InputModal from "../../UI/inputModal/Input";
import Button from "../../UI/Button/Button";
import Modal from "../Modal";
import { openModalAddCompanys } from "../../../store/modal";
import { createCompany } from "../../../api/requests/company";
import { getCompanyThunk } from "../../../store/company";

function ContentModal({isShow = false, setShow = () => {}}) {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: '',
    phoneNumber: '',
    name: '',
    address: ''
  });
  
  const handleSave = () => {
    createCompany(data).then((res) => {
      dispatch(changeUpdatingReloadStatus("start"));
      setShow(false);
      dispatch(getCompanyThunk())
    }).catch((err) =>{
    });
  }

  const handleData = (val, key) => {
    setData((obj) => {
      return {...obj, [key]: val };
    });
  }

  return (  
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.top}>
            <h3>New Company</h3>
            <div className={styles.close} onClick={() => setShow(false)}>+</div>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.contentItem}>
            <div className={styles.label}>Name</div>
            <InputModal placeholder="name" name="" defaultValue="" onClick={(val) => handleData(val, "name")}/>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.label}>Address</div>
            <InputModal placeholder="Address" name="" defaultValue="" onClick={(val) => handleData(val, "address")}/>
          </div>
          
          <div className={styles.contentItem}>
            <div className={styles.label}>Phone</div>
            <InputModal placeholder="9539999999999" name="" defaultValue=""  onClick={(val) => handleData(val, "phoneNumber")}/>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.label}>Email</div>
            <InputModal placeholder="mail@mail.com" name="" defaultValue=""  onClick={(val) => handleData(val, "email")}/>
          </div>
        </div>

        <div className={styles.buttons} onClick={handleSave}>
          <Button label={'Save'}/>
        </div>
      </div>
    </>
  );
}

function ModalCreateCompanys() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const modalCreateCompanys = useSelector((state) => state.modal.modalAddCompanys);

  const setShowModalAddCompanys = (val) => {
    dispatch(openModalAddCompanys(val));
  };

  useEffect(() => {
    setShowModal(modalCreateCompanys);
  }, [modalCreateCompanys]);
  return (
    showModal && <>
      <Modal contentModal={<ContentModal isShow={showModal} setShow={setShowModalAddCompanys}/>}  setShow={setShowModalAddCompanys} />
    </>
  );
}

export default ModalCreateCompanys;
