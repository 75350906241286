import Button from "../../../UI/Button/Button";
import styles from "./style.module.scss";
import { useDispatch } from "react-redux";
import { openModalAddCompanys } from "../../../../store/modal";

function SettingCompanyHeader() {
  const dispatch = useDispatch();
  return (
    <>
        <div className={styles.secondBlock}>
          <Button
            label="Create Company"
            styleType=""
            onClick={() => dispatch(openModalAddCompanys(true))}
          />
        </div>
        <div></div>
    </>
  );
}

export default SettingCompanyHeader;
