import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/UI/Table/Table";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { openModalEditCompany } from "../../../store/modal";
import Button from "../../../components/UI/Button/Button";


function CustomComponent({item}) {
  const dispatch = useDispatch();
  
  const handleEdit = () => {
    dispatch(openModalEditCompany({
      open: true,
      name: item.name,
      phoneNumber: item.phoneNumber,
      email: item.email,
      address: item.address,
      _id: item._id,
    }));
  }

  return (<>
    <Button icon={<ModeEditIcon />} onClick={handleEdit} className={styles.button}/>
  </>)
}
function CompanyPage() {
  const dispatch = useDispatch();
  // const [listCompany, setListCompany] = useState([]);
  const reloadMarker = useSelector((state) => state.reload.reloadStatus);
  const listCompany = useSelector(
    (state) => state.company.getCompanys
  );
  // useEffect(() => {
  //   getCompanies().then(res => {
  //     setListCompany(res);
  //   })
  // }, []);

  // useEffect(() => {
  //   dispatch(getCompanyThunk()).then(res => {
  //     let asd = res
  //   });
  //   dispatch(getCompanyThunk()).then(res => {
  //     // setListCompany(res);
  //     dispatch(changeUpdatingReloadStatus("finish"))
  //   })
  // }, [reloadMarker])

  return (
    <div className={styles.container}>
      <div className={styles.columnsContainer}>
        <Table header={[
          {
            key: "name",
            name: "Name",
            sort: "asc"
          },
          {
            key: "address",
            name: "Address",
            sort: "asc"
          },
          {
            key: "phoneNumber",
            name: "Phone",
            sort: "asc"
          },
          {
            key: "email",
            name: "Email",
            sort: "asc"
          },
          {
            key: "created_at",
            name: "Created",
            sort: "asc"
          },
          {
            key: "",
            name: "",
            sort: "",
            component: CustomComponent
          }

        ]}
        list={listCompany}
        />
      </div>
    </div>
  );
}

export default CompanyPage;
