import styles from "./style.module.scss";
import Table from "../../../components/UI/Table/Table";
import { useEffect, useState } from "react";
import { getUsersData } from "../../../api/requests/userAuth";
import { useDispatch, useSelector } from "react-redux";
import { changeUpdatingReloadStatus } from "../../../store/reloadMarker";
import Button from "../../../components/UI/Button/Button";
import { openModalEditUser } from "../../../store/modal";
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { api } from "../../../api/api";

// function CustomComponent({companyId}) {
//   return (<>
//     {!companyId && <Button 
//       label="Add Company"
//       styleType=""
//       onClick={() => setShowEditModal(true)}/>} 
//     <Button />  
//   </>);
// }
function CustomComponent({item}) {
  const dispatch = useDispatch();
  
  const handleEdit = () => {
    dispatch(openModalEditUser({
      open: true,
      role: item.role,
      company: item.company,
      companyId: item.companyId,
      email: item.email,
      firstName: item.firstName,
      lastName: item.lastName,
      phone: item.phoneNumber,
      _id: item._id,
    }));
  }

  const handleDelete = () => {
    api.userAuth.deleteUser(item._id).then(() => {
      dispatch(changeUpdatingReloadStatus("start"));
    });
  }
  
  return (<>
    <Button icon={<ModeEditIcon />} onClick={handleEdit} className={styles.button}/>
    <Button icon={<DeleteIcon/>} styleType="red" onClick={handleDelete}/>
  </>)
}
function UsersPage() {
  const dispatch = useDispatch();
  const [listUsers, setListUsers] = useState([]);
  const reloadMarker = useSelector((state) => state.reload.reloadStatus);

  useEffect(() => {
    getUsersData().then(res => {
      setListUsers(res);
    })
  }, []);

  useEffect(() => {
    if(reloadMarker === 'start') {
      getUsersData().then(res => {
        setListUsers(res);
        dispatch(changeUpdatingReloadStatus("finish"))
      })
    }
  }, [reloadMarker])



  return (
    <div className={styles.container}>
      <div className={styles.columnsContainer}>
        <Table header={[
          {
            key: "firstName",
            name: "First Name",
            sort: "asc"
          },
          {
            key: "lastName",
            name: "Last Name",
            sort: "asc"
          },
          {
            key: "email",
            name: "Email",
            sort: "asc"
          },
          {
            key: "company",
            name: "Company",
            sort: "asc"
          },
          {
            key: "role",
            name: "Role",
            sort: "asc"
          },
          {
            key: "created_at",
            name: "Created",
            sort: "asc"
          },
          
          {
            key: "",
            name: "",
            sort: "",
            component: CustomComponent
          }
        ]}
        list={listUsers}
        />
      </div>
    </div>
  );
}

export default UsersPage;
