import { createSlice } from "@reduxjs/toolkit";
// import { api } from "../../../api/api";
// import {
//   extraReducers,
//   fulfilled,
//   pending,
//   rejected,
// } from "../../../utils/sharedExtraReducers";
// import { changeUpdatingHeatStressStatus } from "../../updatingSlice";

const initialState = {
  getVentilationData: {
    response: null,
    status: null,
    error: null,
  },
};

// export const getVentilationDataThunk = createAsyncThunk(
//   "ventilationDataForUser/getVentilationData",
//   async function (payload, { rejectWithValue, dispatch }) {
//     const { cycleId, data, isNow, isUpdating } = payload;
//     try {
//       if (isUpdating) dispatch(changeUpdatingHeatStressStatus("started"));
//       const response = await api.dashboard.getVentilationData(
//         cycleId,
//         data,
//         isNow
//       );
//       dispatch(addVentilationDataToState(response.data));
//       if (isUpdating) dispatch(changeUpdatingHeatStressStatus("finished"));
//     } catch (error) {
//       if (isUpdating) dispatch(changeUpdatingHeatStressStatus("finished"));
//       return rejectWithValue(error.message);
//     }
//   }
// );

const ventilationDataForUserSlice = createSlice({
  name: "ventilationDataForUser",
  initialState,
  reducers: {
    // addVentilationDataToState(state, action) {
    //   // state.getVentilationData.response = action.payload;

    //   if (!action.payload) {
    //     state.getVentilationData.response = action.payload;
    //   } else {
    //     // const { range, ...values } = action.payload;
    //     // range.none = range.low;
    //     let range = { };
    //     range.low = 120;
    //     // range.low = range.high;
    //     range.normal = 140;
    //     range.high = 160;
    //     range.critical = 200;
    //     state.getVentilationData.response = { range, values: {...action.payload} };
    //   }
    // },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(
  //       getVentilationDataThunk.pending,
  //       extraReducers(pending, "getVentilationData")
  //     )
  //     .addCase(
  //       getVentilationDataThunk.rejected,
  //       extraReducers(rejected, "getVentilationData")
  //     )
  //     .addCase(
  //       getVentilationDataThunk.fulfilled,
  //       extraReducers(fulfilled, "getVentilationData")
  //     );
  // },
});

// export const { addVentilationDataToState } =
//   ventilationDataForUserSlice.actions;
export default ventilationDataForUserSlice.reducer;
