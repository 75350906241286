import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalAddFarm: false,
  modalAddCoop: {
    farmId: null,
    open: false
  },
  modalAddUser: {
    farmId: null,
    open: false
  },
  modalAttachUserFarm: {
    open: false
  },
  modalAttachUserCoop: {
    open: false,
    coopsList: []
  },
  modalEditUser: {
    open: false,
    role: '',
    company: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    address: '',
    password: '',
    _id: '',
  },
  modalEditCompany: {
    open: false,
    name: '',
    address: '',
    email: '',
    phoneNumber: ''
  },
  modalAddCompanys: false
};

const modal = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModalAddFarm(state, action) {
      state.modalAddFarm = action.payload;
    },
    openModalAddUser(state, action) {
      state.modalAddUser = {...state.modalAddUser, ...action.payload};
    },
    openModalAddCompanys(state, action) {
      state.modalAddCompanys = action.payload;
    },
    openModalAddCoop(state, action) {
      state.modalAddCoop = {...state.modalAddCoop,...action.payload};
    },
    openModalAttachUserFarm(state, action) {
      state.modalAttachUserFarm = {...state.modalAttachUserFarm,...action.payload};
    },
    openModalAttachUserCoop(state, action) {
      state.modalAttachUserCoop = {...state.modalAttachUserCoop,...action.payload};
    },
    openModalEditUser(state, action) {
      state.modalEditUser = {...state.modalEditUser,...action.payload};
    },
    openModalEditCompany(state, action) {
      state.modalEditCompany = {...state.modalEditCompany,...action.payload};
    },
    resetStateModal(state, action) {
      state = initialState;
    }
  }
});

export const {
  openModalAddFarm, 
  openModalAddCoop, 
  openModalAddUser, 
  openModalAddCompanys,
  openModalAttachUserFarm,
  openModalAttachUserCoop,
  openModalEditUser,
  openModalEditCompany,
  resetStateModal
} = modal.actions;

export default modal.reducer;
