import { useEffect, useState } from "react";
import styles from "./TableCoops.module.scss";
import { getFarmList } from "../../../api/requests/farmsForUsers";
import classNames from "classnames";
import { useParams } from "react-router-dom";


function TableCoops() {
  const [data, setData] = useState([]);
  const {id} = useParams();

  const hoverIn = (id) => {
    setData(data => {
      return data.map(item => {
        item.hover = false;
        if (item._id === id) item.hover = true;
        return item;
      }); 
    })
  };
  useEffect(() => {
    // getFarmList().then((farmList) => {
    //   setData(data => {
    //     return farmList.map(item => {
    //       return item = {...item, hover: false}
    //     })
    //   });
    // });
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.containerItem}>
        <div className={styles.label}>Farm</div>
        <div className={styles.tableBlock}>
          <div className={styles.tableHeader}>
            <div className={styles.item}>Farm (24)</div>
            <div className={styles.item}>Active Coops</div>
          </div>

          

          <div className={styles.tableBody}>
            {data.map(item => (
              <div key={item._id} 
                className={classNames(styles.itemRow, {
                  [styles.hover]: item.hover
                })}
                onMouseOver={()=>hoverIn(item._id)}
                onMouseLeave={()=>hoverIn(0)}
                >
                <div className={styles.item}><div><b>{item.name}</b></div></div>
                <div className={styles.item}><div>{item.coops}</div></div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.containerItem}>
        <div className={styles.label}>Alert</div>
        <div className={styles.tableBlock}>
          <div className={styles.tableHeader}>
            <div className={styles.item}>High</div>
            <div className={styles.item}>Medium</div>
            <div className={styles.item}>Low</div>
            <div className={styles.item}>Total</div>
          </div>
          <div className={styles.tableBody}>
            {data.map(item => (
              <div key={item._id} className={classNames(styles.itemRow, {
                [styles.hover]: item.hover
              })}
              onMouseOver={()=>hoverIn(item._id)}
              onMouseLeave={()=>hoverIn(0)}
              >
                <div className={styles.item}><div><div className={classNames(styles.border,styles.high)}>{item.alerts.high}</div></div></div>
                <div className={styles.item}><div><div className={classNames(styles.border,styles.medium)}>{item.alerts.middle}</div></div></div>
                <div className={styles.item}><div><div className={classNames(styles.border,styles.low)}>{item.alerts.low}</div></div></div>
                <div className={styles.item}><div><div className={classNames(styles.border,styles.total)}>{item.alerts.low + item.alerts.middle + item.alerts.high}</div></div></div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.containerItem}>
        <div className={styles.label}>Data</div>
        <div className={styles.tableBlock}>
          <div className={styles.tableHeader}>
            <div className={styles.item}>Death Rate</div>
            <div className={styles.item}>Growth Rate</div>
            <div className={styles.item}>Water (T)</div>
            <div className={styles.item}>Food (T)</div>
          </div>

          <div className={styles.tableBody}>
            {data.map(item => (
              <div 
                key={item._id} 
                className={classNames(styles.itemRow, {
                  [styles.hover]: item.hover
                })} 
                onMouseOver={()=>hoverIn(item._id)}
                onMouseLeave={()=>hoverIn(0)}
                >
                <div className={styles.item}><div><div className={classNames(
                  styles.border, 
                  {
                    [styles.up]: item.grows >= 0,
                    [styles.down]: item.grows < 0  
                  })}>{item.grows}</div></div></div>
                <div className={styles.item}><div><div className={classNames(
                  styles.border, 
                  {
                    [styles.up]: item.death >= 0,
                    [styles.down]: item.death < 0  
                  })}>{item.death}</div></div></div>
                <div className={styles.item}><div>{item.water}</div></div>
                <div className={styles.item}><div>{item.food}</div></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableCoops;
