import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";
import {
  extraReducers,
  fulfilled,
  pending,
  rejected,
} from "../utils/sharedExtraReducers";

const initialState = {
  getFarms: {
    response: null,
    status: null,
    error: null,
  },
  chosenFarm: null
};

export const getFarmsThunk = createAsyncThunk(
  "farmsForUser/getFarms",
  async function (_, { rejectWithValue, dispatch }) {
    try {
      const response = await api.farms.getFarms();
      const nomalizedFarms = response.data.map((farm) => {
        return {
          _id: farm._id,
          name: farm.farmName,
          coops: farm.coops,
          companyId: farm.companyId || null
        };
      });
      dispatch(addGettedFarmsToState(nomalizedFarms));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const farmsForUserSlice = createSlice({
  name: "farmsForUser",
  initialState,
  reducers: {
    addGettedFarmsToState(state, action) {
      state.getFarms.response = action.payload;
    },
    addChoosenFarm(state, action) {
      state.chosenFarm = action.payload;
    },
    resetStateFarm(state, action) {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFarmsThunk.pending, extraReducers(pending, "getFarms"))
      .addCase(getFarmsThunk.rejected, extraReducers(rejected, "getFarms"))
      .addCase(getFarmsThunk.fulfilled, extraReducers(fulfilled, "getFarms"));
  },
});

export const { addGettedFarmsToState, addChoosenFarm, resetStateFarm } =
  farmsForUserSlice.actions;
export default farmsForUserSlice.reducer;
