import { makeRequest } from "../makeRequest";

const URL = "/Dashboard";

export const getAlertsForUser = (cycleId) => {
  return makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/Alerts`,
  });
};

export const getDeathRateForUser = async (cycleId) => {
  return await makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/Death`,
  }).then((res) => {
    return [...res.data].reduce(
      (accum, item) => {
        let itemValue = item.value;
        let itemExpected = item.expected;
        if (itemValue) {
          itemValue = +itemValue.toFixed(2);
        }
        if (itemExpected) {
          itemExpected = +itemExpected.toFixed(2);
        }
        accum.current.push([item.day, itemValue]);
        accum.planned.push([item.day, itemExpected]);
        return accum;
      },
      { current: [], planned: [] }
    );
  }).catch((err) => {
  });
  
};

export const getGrowthRateForUser = async (cycleId) => {
  const res = await makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/Growth`,
  });
  return [...res.data].reduce(
    (accum, item) => {
      if (item.value) {
        accum.current.push([item.day, item.value]);
      }
      accum.planned.push([item.day, item.expected]);
      return accum;
    },
    { current: [], planned: [] }
  );
};

export const getSensorsStatus = (cycleId, data) => {
  return makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/SensorsStatus?day=${data}`,
  });
};

export const getVentilationData = async (cycleId, data = 1, isNow) => {
  const res = await makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/Ventilation?day=${data}&now=${isNow}`,
  });
  let range = { };
  range.low = 120;
  // range.low = range.high;
  range.normal = 140;
  range.high = 160;
  range.critical = 200;
  return { range, values: {...res.data} };
};

export const getTempAndHumidData = async (cycleId, data = 1) => {
  const res = await makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/TempAndHumid?day=${data}`,
  });

  const { samples, ...tempAndHumidData } = res.data;

  const normalizedSamples = samples.reduce(
    (accum, item) => {
      if (accum.temp[item.position]) {
        if(item.field === "humidity") {
          accum.humid[item.position].push([
            item.time,
            item.value,
          ]);
        }
        if(item.field === "temperature") {
          accum.temp[item.position].push([
            item.time,
            item.value,
          ]);
        }
      }
      return accum;
    },
    {
      temp: { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] },
      humid: { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] },
    }
  );

  return {
    tempAndHumidData,
    samples: normalizedSamples
  }
};

export const getAverageData = async (cycleId, data = 1) => {
  return await makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/TempAndHumidMax?day=${data}`,
  }).then(res => {
    if(res.data.status === "no_cycles") {
      return {
        averageTemp: [],
        averageTempBySensor: [[], [], []],
        averageHumid: [],
        averageHumidBySensor: [[], [], []]
      }
    }
    const { samples } = res.data;
    delete res.data.samples;
    const averageData = res.data;
    const normalizedSamples = samples.reduce(
      (accum, item) => {
        if(item.field === "humidity") {
          accum.averageHumid.push([
            item.time,
            item.value,
          ]);

          accum.averageHumidBySensor[item.position-1].push([
            item.time,
            item.value,
          ]);
        }
        if(item.field === "temperature") {
          accum.averageTemp.push([
            item.time,
            item.value,
          ]);
          
          accum.averageTempBySensor[item.position-1].push([
            item.time,
            item.value,
          ]);
        }
        return accum;
      },
      {
        averageTemp: [],
        averageTempBySensor: [[], [], []],
        averageHumid: [],
        averageHumidBySensor: [[], [], []]
      }
    );

    return {
      averageData,
      samples: normalizedSamples
    }
  }).catch(err => {
  });
  
};

export const getWindAndFeels = async (cycleId, data = 1) => {
  let res = await makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/WindAndFeels?day=${data}`,
  });
  res = {
    wind: res?.data?.data?.wind?.toFixed(2),
    feels: res?.data?.data?.feels?.toFixed(2)
  }
  return res;
};

export const getSound = async (cycleId, data = 1) => {
  let res = await makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/Sound?day=${data}`,
  });
  res = {
    sound: res?.data?.data?.sound?.toFixed(2),
    averageSound: res?.data?.data?.averageSound?.toFixed(2)
  }
  return res;
};

export const getAQIData = (cycleId, data = 1, isNow = true) => {
  return makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/AQI?day=${data}&now=${isNow}`,
  });
};

export const getPHIData = (cycleId, data = 1, isNow = true) => {
  return makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/HealthIndex?day=${data}&now=${isNow}`,
  });
};

export const getVentilationRecomendation = (run, air, day = 1, cycleId) => {
  return makeRequest({
    method: "GET",
    url: `${URL}/${cycleId}/GetVentilationRecomandation?day=${day}&run=${run}&air=${air}`,
  });
};
