import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";

const initialState = {
  getCompanys: []
};

const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    getCompanys(state, action) {
      state.getCompanys = action.payload;
    },
    resetStateCompanys(state, action) {
      state = initialState;
    },
  }
});

export const getCompanyThunk = createAsyncThunk(
  "company/getCompanys",
  async function (_, { rejectWithValue, dispatch }) {
    try {
      const response = await api.company.getCompanies();
      const nomalizedCompany = response.map((company) => {
        return {
          _id: company._id,
          name: company.name,
          email: company.email,
          address: company.address,
          phoneNumber: company.phoneNumber,
          created_at: company.created_at
        };
      });
      dispatch(getCompanys(nomalizedCompany));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const {
  getCompanys, 
  resetStateCompanys
} = company.actions;

export default company.reducer;
