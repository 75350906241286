import { useEffect, useState } from "react";
import styles from "./ModalAttachUserFarm.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InputModal from "../../UI/inputModal/Input";
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";
import { changeUpdatingReloadStatus } from "../../../store/reloadMarker";
import { openModalAddUser, openModalAttachUserFarm } from "../../../store/modal";
import { getPossibListUser, registrationUser } from "../../../api/requests/userAuth";
import Modal from "../Modal";
import { api } from "../../../api/api";

function ContentModal({setShow = () => {}}) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedFarm, setSelectedFarm] = useState("");
  const [userList, setUserList] = useState([]);
  const { role, farms } = useSelector(
    (state) => state.auth.profileData.userData
  );

  const farmList = useSelector(
    (state) => state.farmsForUser.getFarms.response
  );

  const [data, setData] = useState({
    userId: {
      val: "",
      required: true,
      error: false,
    },
    farmId: {
      val:'',
      required: true,
      error: false,
    },
  });
  
  const handleSave = () => {
    let isError = false;
    let req = {};

    for(let item of Object.keys(data)) {
      if(data[item].required) {
        if(!data[item].val) {
          setData((data) => {
            return {...data, [item]: {...data[item], error: true} };
          });
          // if(data[item].roles.includes(role)) {
            isError = true;
          // }
          // data[item].error = true;
        } else {
          setData((data) => {
            return {...data, [item]: {...data[item], error: false} };
          });
          // data[item].error = false;
          req[item] = data[item].val;
          if(item === 'farms') {
            req.farms = data[item].val._id;
          }
          if(item === 'company') {
            req.company = data[item].val._id;
          }
        }
      } else { 
        req[item] = data[item].val;
      }
    }
    if(isError) return;
    api.farms.attachUserFarm(req).then((res) => {
      dispatch(changeUpdatingReloadStatus("start"));
      setShow(false);
    }).catch((err) =>{
    });
  }

  const handleData = (val, key) => {
    setData((obj) => {
      return {...obj, [key]: {...obj[key], val} };
    });

    if(key === 'userId') {
      setSelectedUser(val)
    }
    if(key === 'farmId') {
      setSelectedFarm(val);
      api.userAuth.getPossibListUser(val._id).then(res => {
        setUserList(res.map(i => {return {_id: i._id, name: i.email}}));
      })
    }
  }



  return (  
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.top}>
            <h3>Attach User to Farm</h3>
            <div className={styles.close} onClick={() => setShow(false)}>+</div>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.contentItem}>
            <div className={styles.label}>Farm *</div>
            <Select styleType={data.userId.error && 'error'} name="Farms" activeSelect={selectedFarm}  placeholder="Farms" list={farmList} handleSelect={(e)=>{handleData(e, "farmId")}}/>
          </div>
          {/* {["supervisor", "admin", "user"].includes(role) &&  */}
          <div className={styles.contentItem}>
            <div className={styles.label}>User *</div>
            <Select styleType={data.farmId.error && 'error'} name="Users" activeSelect={selectedUser} placeholder="User" list={userList} handleSelect={(e)=>{handleData(e, "userId")}}/>
          </div>
          {/* } */}
          
        </div>

        <div className={styles.buttons} onClick={handleSave}>
          <Button label={'Save'}/>
        </div>
      </div>
    </>
  );
}

function ModalAttachUserFarm() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const modalCreateUser = useSelector((state) => state.modal.modalAttachUserFarm);

  const setShowModalAddUser = (val) => {
    dispatch(openModalAttachUserFarm({open: val}));
  };

  useEffect(() => {
    setShowModal(modalCreateUser.open);
  }, [modalCreateUser]);

  return (
    showModal && <>
      <Modal contentModal={<ContentModal isShow={showModal} setShow={setShowModalAddUser}/>}  setShow={setShowModalAddUser} />
    </>
  );
}

export default ModalAttachUserFarm;
