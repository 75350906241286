import { createInfoThunk } from "../../store/notification";
import { makeRequest } from "../makeRequest";
let store;
export const injectStoreFarm = (_store) => {
  store = _store;
};
const URL = "/Farms";

export const getFarms = () => {
  return makeRequest({
    method: "GET",
    url: URL,
  });
};

export const getFarmsByCompany = (id) => {
  return makeRequest({
    method: "GET",
    url: `${URL}/company/${id}`,
  });
};

export const getFarmList = async () => {
  const res = await makeRequest({
    method: "GET",
    url: `${URL}/listInfo`,
  });

  if(res.data.status === 'ok') {
    return res.data.data;
  }
};

export const createFarm = async (data) => {
  return await makeRequest({
    method: "POST",
    url: `${URL}/new`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("Farm is created"));
    return res;
  }).catch(err => {
    console.error(err);
  });
};

export const attachUserFarm = async (data) => {
  return await makeRequest({
    method: "POST",
    url: `${URL}/attach`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("User Farm is attached"));
    return res?.data?.data;
  }).catch(err => {
    console.error(err);
  });
};