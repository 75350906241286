import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api/api";

const initialState = {
  updatingFarm: null,
  getFarmsForAdmin: {
    response: null,
    status: null,
    error: null,
  },
  chooseFarm: {},
  createCoopForFarm: {
    status: null,
    error: null,
  }
};

export const getFarmByIdThunk = createAsyncThunk(
  "farmsData/getFarmById",
  async function (farmId, { rejectWithValue, dispatch }) {
    try {
      const response = await api.adminFarms.getFarmById(farmId);
      dispatch(addUpdatingFarmToState(response.data));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);



const farmsDataSlice = createSlice({
  name: "farmsData",
  initialState,
  reducers: {
    addUpdatingFarmToState(state, action) {
      state.updatingFarm = action.payload;
    },
    addGettedFarmsForAdminToState(state, action) {
      state.getFarmsForAdmin.response = action.payload;
    },
    updateUpdatingFarm(state, action) {
      state.updatingFarm = action.payload;
    },
    updateCurrentFarm(state, action) {
      state.chooseFarm = action.payload;
    }
  }
});

export const {
  addUpdatingFarmToState,
  addGettedFarmsForAdminToState,
  updateUpdatingFarm,
  updateCurrentFarm
} = farmsDataSlice.actions;
export default farmsDataSlice.reducer;
