import { useEffect, useState } from "react";
import styles from "./ModalCreateFarm.module.scss";
import { useDispatch, useSelector } from "react-redux";
import InputModal from "../../UI/inputModal/Input";
import Button from "../../UI/Button/Button";
import Modal from "../Modal";
import { getFarmsThunk } from "../../../store/farmsForUserSlice";
import { changeUpdatingReloadStatus } from "../../../store/reloadMarker";
import { openModalAddFarm } from "../../../store/modal";
import { createFarm } from "../../../api/requests/farmsForUsers";
import Select from "../../UI/Select/Select";

function ContentModal({isShow = false, setShow = () => {}}) {
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState("");
  const listCompany = useSelector(
    (state) => state.company.getCompanys
  );
  const { role, farms } = useSelector(
    (state) => state.auth.profileData.userData
  );

  const [data, setData] = useState({
    company: {
      val: '',
      required: true,
      error: false,
      roles: ['supervisor']
    },
    name: {
      val: '',
      required: true,
      error: false
    },
  });
  
  const handleSave = () => {
    let req = {};
    let isError = false;
    for(let item of Object.keys(data)) {
      if(data[item].required) {
        if(!data[item].val) {
          setData((data) => {
            return {...data, [item]: {...data[item], error: true} };
          });
          if(data[item]?.roles?.includes(role)) {
            isError = true;
          }
          // data[item].error = true;
        } else {
          setData((data) => {
            return {...data, [item]: {...data[item], error: false} };
          });
          // data[item].error = false;
          req[item] = data[item].val;
          
          if(item === 'company') {
            req.company = data[item].val._id;
          }
        }
      } else { 
        req[item] = data[item].val;
      }
    }
    if(isError) return;
    createFarm(req).then((res) => {
      dispatch(changeUpdatingReloadStatus("start"));
      setShow(false);
      dispatch(getFarmsThunk());
    }).catch((err) =>{
    });
  }

  const handleData = (val, key) => {
    setData((obj) => {
      return {...obj, [key]: {...obj[key], val} };
    });
    if(key === 'company') {
      setSelectedCompany(val);
    }
  }

  return (  
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.top}>
            <h3>New Farm</h3>
            <div className={styles.close} onClick={() => setShow(false)}>+</div>
          </div>
        </div>

        <div className={styles.content}>
        {["supervisor"].includes(role) && 
          <div className={styles.contentItem}>
            <div className={styles.label}>Company *</div>
            <Select styleType={data.company.error && 'error'} name="Company" activeSelect={selectedCompany} placeholder="Company" list={listCompany} handleSelect={(e)=>{handleData(e, "company")}}/>
          </div>}
          <div className={styles.contentItem}>
            <div className={styles.label}>Name</div>
            <InputModal styleType={data.name.error && 'error'} placeholder="Name" name="" defaultValue="" onClick={(val) => handleData(val, "name")}/>
          </div>
        </div>

        <div className={styles.buttons} onClick={handleSave}>
          <Button label={'Save'}/>
        </div>
      </div>
    </>
  );
}

function ModalCreateFarm() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const modalCreateFarm = useSelector((state) => state.modal.modalAddFarm);

  const setShowModalAddFarm = (val) => {
    dispatch(openModalAddFarm(val));
  };

  useEffect(() => {
    setShowModal(modalCreateFarm);
  }, [modalCreateFarm]);
  
  return (
    showModal && <>
      <Modal contentModal={<ContentModal isShow={showModal} setShow={setShowModalAddFarm}/>}  setShow={setShowModalAddFarm} />
    </>
  );
}

export default ModalCreateFarm;
