import { useEffect, useState } from "react";
import styles from "./ModalEditCompanys.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { changeUpdatingReloadStatus } from "../../../store/reloadMarker";
import InputModal from "../../UI/inputModal/Input";
import Button from "../../UI/Button/Button";
import Modal from "../Modal";
import { openModalEditCompany } from "../../../store/modal";
import { editCompanies } from "../../../api/requests/company";
import { getCompanyThunk } from "../../../store/company";

function ContentModal({isShow = false, setShow = () => {}}) {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: '',
    phoneNumber: '',
    name: '',
    address: ''
  });
  const modalEditCompanys = useSelector((state) => state.modal.modalEditCompany);

  
  const handleSave = () => {
    editCompanies(modalEditCompanys._id, data).then((res) => {
      dispatch(changeUpdatingReloadStatus("start"));
      setShow(false);
      dispatch(getCompanyThunk())
    }).catch((err) =>{
    });
  }

  const handleData = (val, key) => {
    setData((obj) => {
      return {...obj, [key]: val };
    });
  }

  useEffect(() => {
    handleData(modalEditCompanys.name, "name");
    handleData(modalEditCompanys.email, "email");
    handleData(modalEditCompanys.phoneNumber, "phoneNumber");
    handleData(modalEditCompanys.address, "address");
  }, [modalEditCompanys]);

  return (  
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.top}>
            <h3>Edit Company</h3>
            <div className={styles.close} onClick={() => setShow(false)}>+</div>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.contentItem}>
            <div className={styles.label}>Name</div>
            <InputModal placeholder="name" name="" defaultValue={data.name} onClick={(val) => handleData(val, "name")}/>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.label}>Address</div>
            <InputModal placeholder="Address" name="" defaultValue={data.address} onClick={(val) => handleData(val, "address")}/>
          </div>
          
          <div className={styles.contentItem}>
            <div className={styles.label}>Phone</div>
            <InputModal placeholder="9539999999999" name="" defaultValue={data.phoneNumber} onClick={(val) => handleData(val, "phoneNumber")}/>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.label}>Email</div>
            <InputModal placeholder="mail@mail.com" name="" defaultValue={data.email} onClick={(val) => handleData(val, "email")}/>
          </div>
        </div>

        <div className={styles.buttons} onClick={handleSave}>
          <Button label={'Save'}/>
        </div>
      </div>
    </>
  );
}

function ModalEditCompanys() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const modalEditCompanys = useSelector((state) => state.modal.modalEditCompany);

  const setShowModalEditCompanys = (val) => {
    dispatch(openModalEditCompany({open:val}));
  };

  useEffect(() => {
    setShowModal(modalEditCompanys.open);
  }, [modalEditCompanys]);
  return (
    showModal && <>
      <Modal contentModal={<ContentModal isShow={showModal} setShow={setShowModalEditCompanys}/>} setShow={setShowModalEditCompanys}/>
    </>
  );
}

export default ModalEditCompanys;
