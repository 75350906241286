import style from "./style.module.scss";
import { ReactComponent as DashboardIcon } from '../../../assets/images/dashboard.svg';
import { ReactComponent as SignalIcon } from '../../../assets/images/signal.svg';
import { ReactComponent as CalculateIcon } from '../../../assets/images/calculate.svg';
import { ReactComponent as PieIcon } from '../../../assets/images/pie.svg';
import { ReactComponent as LanguageIcon } from '../../../assets/images/language.svg';
import { ReactComponent as AccountIcon } from '../../../assets/images/account.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/images/exit.svg';
import AddIcon from '@mui/icons-material/Add';
// import SettingsIcon from '@mui/icons-material/Settings';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PeopleIcon from '@mui/icons-material/People';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "../UI/Button/Button";
import { useTranslation } from "react-i18next";
import AdminMenu from "./adminMenu/AdminMenu";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/authSlice";
import { addChoosenFarm, addGettedFarmsToState, resetStateFarm } from "../../store/farmsForUserSlice";
import { addChosenCoopsToState, addChosenCycleToState, addCyclesToState, addGettedCoopToState, getCoopByIdThunk, resetStateCoop, setActiveCycle } from "../../store/coopsForUserSlice";
import { openModalAddCoop, openModalAddFarm, openModalAddUser, openModalAttachUserCoop, openModalAttachUserFarm, resetStateModal } from "../../store/modal";
import { current } from "@reduxjs/toolkit";
import { resetStateCompanys } from "../../store/company";
import OutsideAlerter from "../../hooks/useOutsideAlerter";

// import { useNavigate } from "react-router";
// import { useDispatch } from "react-redux";
// import { useTranslation } from "react-i18next";

function Menu() {
  const [open, setOpen] = useState(false);
  const [openFarms, setOpenFarms] = useState(false);
  // const [openSetting, setOpenSettings] = useState(false);
  const [openCoop, setOpenCoop] = useState(false);
  const [coops, setCoops] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFarms, setSelectedFarms] = useState(0);
  const [selectedDataFarms, setSelectedDataFarms] = useState(null);
  // const [selectedCoop, setSelectedCoop] = useState(0);
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { firstName, lastName, email, role } = useSelector(
    (state) => state.auth.profileData.userData
  );
  const cycleIdFromLocalStorage = useSelector(
    (state) => state.coopsForUser.cycleIdFromLocalStorage
  );
  const growthDayFromLocalStorage = useSelector(
    (state) => state.coopsForUser.growthDayFromLocalStorage
  );

  const farms = useSelector((state) => state.farmsForUser.getFarms.response) || [];
  // const settings = [
  //   {
  //     id: 1,
  //     name: "Company",
  //     url: "/settings/company",
  //     access: ["supervisor"]
  //   },
  //   {
  //     id: 2,
  //     name: "User",
  //     url: "/settings/user",
  //     access: ["supervisor", "admin", "user"]
  //   }
  // ];
  const handleOpenMenu = () => {
    setOpen(!open);
  };

  const handlerSelectFarm = (item) => {
    setSelectedFarms(selectedFarms !== item._id ? item._id : 0);
    setOpenCoop(selectedFarms !== item._id);
    setCoops(item.coops);
    setSelectedDataFarms(item);
  };

  const handlerSelectCoop = (item) =>  {
    localStorage.setItem("SALUS_COOP_ID", item._id);
    dispatch(getCoopByIdThunk({chosenCoopId: item._id, cycleIdFromLocalStorage, growthDayFromLocalStorage}));
    handlerOpenSubMenu();
    dispatch(addChoosenFarm(selectedDataFarms));
    dispatch(addChosenCoopsToState(item));
  }

  const changeLanguage = () => {
    setLanguage(language === 'en' ? 'he' : 'en');
    i18n.changeLanguage(language === 'en' ? 'he' : 'en');
  }

  const handlerOpenSubMenu = (id) => {
    switch (id) {
      case 'farm':
        setOpenAdmin(false);
        // setOpenSettings(false);
        setOpenFarms(!openFarms);
        break;
      case 'admin':
        setOpenFarms(false);
        // setOpenSettings(false);
        setOpenAdmin(!openAdmin);
        break;
      case 'setting':
        setOpenAdmin(false);
        setOpenFarms(false);
        setOpenCoop(false);
        // setOpenSettings(!openSetting);
        break;
      default:
        // if(openFarms || openSetting || openAdmin || openCoop) {
          if(openFarms || openAdmin || openCoop) {
          setOpenFarms(false);
          setOpenAdmin(false);
          setOpenFarms(false);
          setOpenCoop(false);
          // setOpenSettings(false);
        }
        break;
    }
  }

  const onLogout = () => {
    dispatch(logoutUser());
    dispatch(addGettedFarmsToState(null));
    dispatch(addGettedCoopToState(null));
    dispatch(addChosenCycleToState(""));
    dispatch(setActiveCycle(""));
    dispatch(resetStateModal());
    dispatch(resetStateCompanys());
    dispatch(resetStateFarm());
    dispatch(resetStateCoop());

    dispatch(addChoosenFarm(null));
    dispatch(addCyclesToState([]));
    window.reset();
  };

  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  useEffect(() => {
    if(selectedFarms) {
      let farm = farms.find(item => item._id === selectedFarms);
      setCoops(farm?.coops);
    }
  }, [farms]);
  return (
    <div className={[style.menu, open ? style.open : ''].join(' ')}>
      <div className={style.mainMenu}>

        <ul className={style.list}>
          <div className={style.openMenu} onClick={handleOpenMenu}>
            {open ? <ArrowBackIcon /> : <ArrowForwardIcon />}
          </div>
          <li className={style.active}>
            <Link to="/dashboard" className={style.farm} onClick={() => handlerOpenSubMenu('farm')}>
              <DashboardIcon />
              <span className={style.nameItem}>Farms</span>
            </Link>
          </li>
          <li>
            <Link to="/data">
              <SignalIcon />
              <span className={style.nameItem}>Charts</span>
            </Link>
          </li>
          <li>
            <Link to="/calculator">
              <CalculateIcon />
              <span className={style.nameItem}>Ventilation Calculator</span>
            </Link>
          </li>
          <li>
            <Link to="/report">
              <PieIcon />
              <span className={style.nameItem}>Report</span>
            </Link>
          </li>
          {
            ["supervisor"].includes(role) && 
            <li>
              <Link to="/company">
                <StorefrontIcon />
                <span className={style.nameItem}>Company</span>
              </Link>
            </li>
          }
          {
            ["supervisor", "admin", "user"].includes(role) && 
            <li>
              <Link to="/users">
                <PeopleIcon />
                <span className={style.nameItem}>Users</span>
              </Link>
            </li>
          }
          

          <li>
            <Link to="" onClick={changeLanguage}>
              <LanguageIcon />
              <span className={style.nameItem}>{language}</span>
            </Link>
          </li>
        </ul>
        <div className={style.bottomBlock}>
          <div className={style.bottomBlockItem}>
            <Link to="" className={style.bottomBlockIcon} onClick={() => handlerOpenSubMenu('admin')}>
              <AccountIcon />
              <span className={style.nameItem}>
                {firstName} {lastName}
                {firstName && lastName && <br />}
                {email}
              </span>
            </Link>
          </div>
          <div className={style.bottomBlockItem}>
            <Link to="" className={style.bottomBlockIcon} onClick={onLogout}>
              <LogoutIcon />
              <span className={style.nameItem}>{t("HEADER.LOG_OUT")}</span>
            </Link>
          </div>
        </div>
      </div>
      {openFarms && <OutsideAlerter handler={()=>{handlerOpenSubMenu()}} className={style.outsideBlock}>
      <div className={style.outsideBlock}>
      {openAdmin && <AdminMenu />}
      {openFarms && <div className={style.listFarms} >
        {farms.map((item) => <div key={item._id} className={[style.listFarmsItem, selectedFarms === item._id && style.active].join(' ')}
          onClick={() => handlerSelectFarm(item)}
        >
          {item.name}
        </div>)}
        <div className={style.listFarmsButtons}>
          { ["supervisor", "admin"].includes(role) && 
            <Button
            label='Create Farm'
            className={style.buttonFarm}
            icon={<AddIcon />}
            onClick={() => {dispatch(openModalAddFarm(true))}}
          />}

          { ["supervisor", "admin", "user"].includes(role) && 
          <Button
            label='Add User'
            icon={<AddIcon />}
            onClick={() => {dispatch(openModalAttachUserFarm({open: true}))}}
          />}
        </div>
      </div>}
      {openCoop && <div className={style.listFarms} >
        <div className={[style.listFarmsItem, style.active].join(' ')}>
          {farms.find(item => item._id === selectedFarms)?.name}
        </div>
        {/* <div className={[style.listFarmsItem, selectedCoop === 1 && style.active].join(' ')}
                onClick={() => setSelectedFarms(1)}
            > */}
        {coops.map((item) => <div key={item._id} className={[style.listFarmsItem].join(' ')}
          onClick={() => handlerSelectCoop(item)}
        >
          {item.name}
        </div>)}
        
        <div className={style.listFarmsButtons}>
          { ["supervisor", "admin", "user"].includes(role) && 
          <Button
            label='Create Coop'
            icon={<AddIcon />}
            className={style.buttonFarm}
            onClick={() => {dispatch(openModalAddCoop({open: true, farmId: selectedFarms}))}}
          />}
          { ["supervisor", "admin", "user"].includes(role) && 
          <Button
            label='Add User'
            icon={<AddIcon />}
            onClick={() => {dispatch(openModalAttachUserCoop({open: true, coopsList: farms.find(item => item._id === selectedFarms)?.coops}))}}
          />}
        </div>
      </div>}
      {/* {openSetting && <div className={style.listFarms} >
        {settings.map((item) => {
          if (item.access.includes(role)) {
            return (
              <div 
              key={item.id} 
              className={[style.listFarmsItem, selectedFarms === item._id && style.active].join(' ')}
              onClick={() => handlerOpenSubMenu()}>
                <Link to={item.url} >
                  {item.name}
                </Link>
              </div>
            );  
          }
      })} */}
      {/* </div>} */}
      </div>
      </OutsideAlerter>}
    </div>
  );
}

export default Menu;
