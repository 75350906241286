import style from "./style.module.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// let list = [
//   { _id: "234234", name, email }
// ];
// let header = [
//   {
//     key: "_id",
//     name: "ID",
//     sort: "asc"
//   }
// ]
function Table({ list = [], header = [] }) {

  return (
    <>
      <table>
        <thead>
          <tr>
            {header.map(item => (
              <th key={item.key} onClick={() => {
                header.forEach(headerItem => {
                  if (headerItem.key === item.key) {
                    headerItem.sort = headerItem.sort === "asc"? "desc" : "asc";
                  } else {
                    headerItem.sort = null;
                  }
                });
                header = [...header];
              }}>
                <div className={style.headItem}> 
                  {item.name}
                  {item.sort && <span>{item.sort === "asc"? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</span>}      
                </div>
                </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {list.map(item => (
            <tr key={item._id}>
              {header.map(head => {
                return <td key={head.key}><div className={style.bodyItem}>{head.component?<head.component item={item}/>:item[head.key]}</div></td>;
              })}
            </tr>
            
          ))}
        </tbody>
      </table>
    </>

  );
}

export default Table;
