import ReactEChart from "echarts-for-react";
import { chooseColor, chooseMark } from "../../../../../utils/chooseColor";
import { useTranslation } from "react-i18next";

function Chart({ samples, lineName, range }) {
  let minValueItem = samples.find(item => item[1] <= range.min);
  let minValueItemIndex = samples.findIndex(item => item[1] <= range.min);
  let maxValueItem = samples.find(item => item[1] >= range.max);
  let maxValueItemIndex = samples.findIndex(item => item[1] >= range.max);
  const values = samples.map(i => i[1]);
  let minValue;
  let minValueIndex;
  let maxValue;
  let maxValueIndex;
  if (!minValueItem) {
    minValue = Math.min.apply(null, values);
    minValueIndex = values.findIndex((v) => v === minValue);
  } else {
    minValue = minValueItem[1];
    minValueIndex = minValueItemIndex
  }
  if (!maxValueItem) {
    maxValue = Math.max.apply(null, values);
    maxValueIndex = values.findIndex((v) => v === maxValue);
  } else {
    maxValue = maxValueItem[1];
    maxValueIndex = maxValueItemIndex;
  }
  const { i18n } = useTranslation();
  const eChartsOption = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      left: "-40",
      right: "5",
      bottom: "0",
      top: "5",
      containLabel: true,
    },
    xAxis: {
      show: false,
      type: "time",
    },
    yAxis: {
      show: false,
      min: range.min,
      max: range.max,
      type: "value",
    },
    series: [
      {
        name: lineName,
        lineStyle: {
          color: chooseColor(minValue, range),
        },
        type: "line",
        data: samples,
        smooth: true,
        symbol: (value, index) => {
          return (value[1] === minValue && index.dataIndex === minValueIndex) ||
            (value[1] === maxValue && index.dataIndex === maxValueIndex)
            ? chooseMark(value[1], range)
            : "none";
        },
        symbolSize: "15",
      },
    ],
  };

  return (
    <ReactEChart
      option={eChartsOption}
      style={{
        height: "100%",
        width: "100%",
        marginLeft: i18n.language === 'he' ? "-3.5rem" : '0',
        marginRight: i18n.language === 'he' ? "1.8rem" : '0',
      }}
    />
  );
}

export default Chart;
