import { createInfoThunk } from "../../store/notification";
import { makeRequest } from "../makeRequest";
let store;
export const injectStoreCoop = (_store) => {
  store = _store;
};
const URL = "/Coop";

export const getCoopById = (coopId) => {
  return makeRequest({
    method: "GET",
    url: `${URL}/${coopId}`,
  });
};

export const setCountChickens = (coopId, data) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/countChickens`,
    data
  });
};

export const startCycle = async (coopId, data) => {
  return await makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/StartCycle`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("Started new cycle"));
    return res;
  }).catch(err => {
    console.error(err);
  });
};

export const stopCycle = async (coopId) => {
  return await makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/StopCycle`
  }).then(res => {
    store.dispatch(createInfoThunk("Stoped cycle"));
    return res;
  }).catch(err => {
    console.error(err);
  });
};

export const updateDeath = async (coopId, data) => {
  return await makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/UpdateDath`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("Death updated"));
    return res;
  }).catch(err => {
    console.error(err);
  });
};

export const updateGrowth = async (coopId, data) => {
  return await makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/UpdateGrowth`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("Growth updated"));
    return res;
  }).catch(err => {
    console.error(err);
  });
};

export const createCoop = async (data) => {
  return await makeRequest({
    method: "POST",
    url: `${URL}/new`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("New Coop created"));
    return res?.data?.data;
  }).catch(err => {
    console.error(err);
  });
};

export const attachUserCoop = async (data) => {
  return await makeRequest({
    method: "POST",
    url: `${URL}/attach`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("User attached to Coop"));
    return res?.data?.data;
  }).catch(err => {
    console.error(err);
  });
};