import styles from './Input.module.scss'
function InputModal({name="", placeholder="", onClick = () => {}, type="text", defaultValue = '', styleType=""}) {


  return (  
    <div className={[
      styles.inputContent,
      styleType === "error" && styles.error].join(' ')}>
      <label>
        {name}
      </label>

      <input 
        placeholder={placeholder}
        type={type}
        defaultValue={defaultValue}
        onChange={(e) => onClick(e.target.value)}
      />
    </div>
  );
}

export default InputModal;