import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generateRandomKey } from "../utils/utils";
// item = { title: "", type: "error"}
const initialState = {
  notification: [] // item,
};

const notification = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setError(state, action) {
      state.notification = [...state.notification, {title: action.payload, type: "error", key: generateRandomKey()}];
      
    },
    setInfo(state, action) {
      state.notification = [...state.notification, {title: action.payload, type: "info", key: generateRandomKey()}];
      
    },
    deleteFirstNotif (state, action) {
      let notifs = JSON.parse(JSON.stringify(state.notification));
      notifs.shift();
      state.notification = notifs;
    } 
  }
});

export const createErrorThunk = createAsyncThunk(
  "notitfication/createErrorThunk",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      dispatch(setError(data));
      setTimeout(() => {
        dispatch(deleteFirstNotif());
      }, 3000);
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createInfoThunk = createAsyncThunk(
  "notitfication/createInfoThunk",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      dispatch(setInfo(data));
      setTimeout(() => {
        dispatch(deleteFirstNotif());
      }, 3000);
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const {
  setError, 
  setInfo,
  deleteFirstNotif
} = notification.actions;

export default notification.reducer;
