import { createInfoThunk } from "../../store/notification";
import { makeRequest } from "../makeRequest";
let store;
export const injectStoreDevice = (_store) => {
  store = _store;
};
const URL = "/Device";

export const setDeviceArea = async (coopId, data) => {
  return await makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/setDeviceArea`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("Device was attached to area"));
    return res;
  }).catch(err => {
    console.error(err);
  });
};

export const getDevicesArea = async (coopId, data) => {
  const res = await makeRequest({
    method: "GET",
    url: `${URL}/${coopId}/getDevicesArea`
  });

  return res?.data?.data;
};


