import Button from "../../../UI/Button/Button";
import styles from "./style.module.scss";
import { openModalAddUser } from "../../../../store/modal";
import { useDispatch } from "react-redux";

function SettingUserHeader() {
  const dispatch = useDispatch();

  return (
    <>
        <div className={styles.secondBlock}>
          <Button
            label="Create User"
            styleType=""
            onClick={() => dispatch(openModalAddUser({open: true}))}
          />
        </div>
        <div></div>
    </>
  );
}

export default SettingUserHeader;
