import { createInfoThunk } from "../../store/notification";
import { makeRequest } from "../makeRequest";
let store;
export const injectStoreCompany = (_store) => {
  store = _store;
};

export const createCompany = async (data) => {
  return await makeRequest({
    method: "POST",
    url: `/Company`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("Company created successfully"));
    return res;
  }).catch(err => {
    console.error(err);
  });
};

export const getCompanies = async () => {
  const res = await makeRequest({
    method: "GET",
    url: "/Company",
  });
  return res?.data?.data;
};

export const editCompanies = async (id, data) => {
  return await makeRequest({
    method: "PUT",
    url: `/Company/edit/${id}`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("Company edited successfully"));
    return res?.data?.data;
  }).catch(err => {
    console.error(err);
  });
};

export const attachCompanyToUser = async (data) => {
  return await makeRequest({
    method: "POST",
    url: `/Company/attachUser`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("Company Attached to User"));
    return res;
  }).catch(err => {
    console.error(err);
  });
};
