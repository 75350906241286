import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { getRefreshTokenThunk, getUserDataThunk } from "./app/store/authSlice";
import AppRoutes from "./app/routes/AppRoutes";
import { getFarmsThunk } from "./app/store/farmsForUserSlice";
import { getCompanyThunk } from "./app/store/company";

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();
  // document.body.dir = i18n.dir();
  const dispatch = useDispatch();
  const previousUserLanguage = localStorage.getItem("i18nextLng");
  const tokenFromLocalStorage = localStorage.getItem("SALUS_ACCESS_TOKEN");
  const tokenFromStore = useSelector(
    (state) => state.auth.authData.accessToken
  );
  const { role } = useSelector(
    (state) => state.auth.profileData.userData
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (previousUserLanguage) {
      i18n.changeLanguage(previousUserLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tokenFromLocalStorage) {
      dispatch(getRefreshTokenThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (tokenFromStore) {
    //   navigate("/dashboard");
    // }
    if (
      !tokenFromStore &&
      location.pathname !== "/" &&
      !tokenFromLocalStorage
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFromStore]);

  useEffect(() => {
    if (tokenFromStore) {
      dispatch(getUserDataThunk());
      dispatch(getFarmsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFromStore]);
  
  useEffect(() => {
      if(["supervisor"].includes(role)) {
        dispatch(getCompanyThunk());
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);


  return <AppRoutes />;
}

export default App;
