import { createErrorThunk, createInfoThunk } from "../../store/notification";
import { makeRequest } from "../makeRequest";
let store;
export const injectStoreUser = (_store) => {
  store = _store;
};
export const login = (data) => {
  return makeRequest({
    method: "POST",
    url: "/Account/Login",
    data,
  });
};

export const getRefreshToken = () => {
  return makeRequest({
    method: "POST",
    url: "/Account/Refresh",
  });
};

export const getUserData = () => {
  return makeRequest({
    method: "GET",
    url: "/Account",
  });
};

export const getUsersData = async () => {
  let res = await makeRequest({
    method: "GET",
    url: "/Account/getUsers",
  });
  let result = [];
  if(res?.data?.data) {
    result = res?.data?.data.map(item => {
      return {
        _id: item._id,
        created_at: item.created_at,
        email: item.email,
        firstName: item.firstName,
        lastName: item.lastName,
        phoneNumber: item.phoneNumber,
        role: item.role,
        company: item.companyId? item.companyId.name: '',
        companyId: item.companyId? item.companyId._id: '',
      };
    });
  }
  return result;
};

export const registrationUser = async (data) => {
  return await makeRequest({
    method: "POST",
    url: `/Account/registration`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("User created successfully"));
    return res;
  }).catch(err => {
    console.error(err);
  });
};


export const getPossibListUser = async (id) => {
  return await makeRequest({
    method: "GET",
    url: `/Account/possibilityListUsers/${id}`,
  }).then(res => {
    return res?.data?.data;
  }).catch(err => {
    console.error(err);
  });
};

export const getPossibListUserByCoop = async (id) => {
  return await makeRequest({
    method: "GET",
    url: `/Account/possibilityListUsersByCoop/${id}`,
  }).then(res => {
    return res?.data?.data;
  }).catch(err => {
    console.error(err);
  });
};

export const putEditUser = async (id, data) => {
  return await makeRequest({
    method: "PUT",
    url: `/Account/edit/${id}`,
    data
  }).then(res => {
    store.dispatch(createInfoThunk("User edited successfully"));
    return res?.data?.data;
  }).catch(err => {
    console.error(err);
  });
};

export const deleteUser = async (id) => {
  return await makeRequest({
    method: "DELETE",
    url: `/Account/delete/${id}`
  }).then(res => {
    store.dispatch(createInfoThunk("User deleted successfully"));
    return res?.data?.data;
  }).catch(err => {
    console.error(err);
  });
};