import ReactEChart from "echarts-for-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import {
//   getDeathRateForUserByCycleIdThunk,
//   removeDeathRateFromState,
// } from "../../../store/dashboardSlices/deathRateForUserSlice";
import CircularProgress from "@mui/material/CircularProgress";
// import useUpdate from "../../../hooks/useUpdate";
import { api } from "../../../api/api";

const curveColors = ["0ACF83", "F24E1E"];

function DeathChart() {
  const { i18n, t } = useTranslation();
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(true);
  // const dispatch = useDispatch();

  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);
  // const data = useSelector(
  //   (state) => state.deathRateForUser.getDeathRateForUser.noramlizedResponse
  // );

  // const cycleIdOfDeathRateToState = useSelector(
  //   (state) => state.deathRateForUser.cycleIdOfDeathRateToState
  // );
  // const downloadingStatus = useSelector(
  //   (state) => state.deathRateForUser.getDeathRateForUser.status
  // );
  // const updatingStatus = useSelector((state) => state.updating.deathRate);

  const defineMaxValueAndInterval = () => {
    if (data) {
      const margin = data.current?.length - data.planned?.length;
      const maxValue = margin > 0 ? data.current?.length : data.planned?.length;
      const interval = Math.round(maxValue / 6);

      return {
        maxValue,
        interval,
      };
    } else {
      return {
        maxValue: null,
        interval: null,
      };
    }
  };

  const { maxValue, interval } = defineMaxValueAndInterval();

  useEffect(() => {
    // if (chosenCycle) {
    //   if (chosenCycle._id !== cycleIdOfDeathRateToState) {
        makeRequest();
    //   }
    // } else {
    //   dispatch(removeDeathRateFromState());
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCycle]);

  const makeRequest = (isNow, isUpdating) => {
    if(!chosenCycle._id) {
      setLoader(false);
      return;
    }
    setLoader(true);
    api.dashboard.getDeathRateForUser(chosenCycle._id).then(data => {
      setData(data);
      setLoader(false);
    });
    // dispatch(
    //   getDeathRateForUserByCycleIdThunk({
    //     chosenCycleId: chosenCycle._id,
    //     isUpdating,
    //   })
    // );
  };

  // useUpdate(makeRequest, 1);

  const eChartsOption = {
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        let colorSpan = (color) =>
          '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
          color +
          '"></span>';
        let rez = "<p>" + params[0].axisValue + "</p>";
        params.forEach((item, index) => {
          let xx =
            '<p style="display:flex; justify-content:space-between">' +
            "<span>" +
            colorSpan(`#${curveColors[index]}`) +
            item.seriesName +
            " - " +
            "</span>" +
            "<span>" +
            "<strong>" +
            (item.data[1] === null ? 0 : item.data[1]) +
            "</strong>" +
            "</span>" +
            "</p>";
          rez += xx;
        });

        return rez;
      },
    },
    grid: {
      left: i18n.language === "en" ? "-1" : "20",
      right: "10",
      bottom: "0",
      top: "0",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      name: "Day",
      nameLocation: "start",
      nameGap: -20,
      nameTextStyle: {
        color: "#414141",
        fontFamily: "Roboto-Regular",
        fontSize: ".75rem",
        verticalAlign: "top",
        lineHeight: "23",
      },
      min: 1,
      max: maxValue ? maxValue : 6,
      interval: interval ? interval : 1,
      axisTick: {
        show: false,
      },
      axisLabel: {
        showMinLabel: false,
        showMaxLabel: true,
        color: "#414141",
        fontFamily: "Roboto-Regular",
        fontSize: ".81rem",
      },
    },
    yAxis: {
      show: true,
      type: "value",
      min: 0,
      max: 18,
      splitNumber: 6,
      axisTick: {
        show: false,
      },
      axisLabel: {
        formatter: function (value, index) {
          return value === 0 ? value : value + "%";
        },
        showMaxLabel: false,
        color: "#414141",
        fontFamily: "Roboto-Regular",
        fontSize: ".81rem",
      },
    },
    series: [
      {
        name: t("CHART.PLANNED"),
        type: "line",
        smooth: true,
        symbol: `image://data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23${curveColors[0]}'/%3E%3C/svg%3E`,
        symbolSize: 7,
        lineStyle: {
          color: `#${curveColors[0]}`,
        },
        data: data?.planned,
      },
      {
        name: t("CHART.CURRENT"),
        type: "line",
        smooth: true,
        symbol: `image://data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23${curveColors[1]}'/%3E%3C/svg%3E`,
        symbolSize: 7,
        lineStyle: {
          color: `#${curveColors[1]}`,
        },
        data: data?.current,
      },
    ],
  };

  return loader ? (
    <CircularProgress />
  ) : (
    <ReactEChart option={eChartsOption} style={{ height: "158px" }} />
  );
}

export default DeathChart;
