import ReactEChart from "echarts-for-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import {
//   getGrowthRateForUserByCycleIdThunk,
//   removeGrowthRateFromState,
// } from "../../../store/dashboardSlices/growthRateForUserSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
// import useUpdate from "../../../hooks/useUpdate";
import { api } from "../../../api/api";

const curveColors = ["0ACF83", "425BFE"];

function GrowthChart() {
  const { t } = useTranslation();

  // const dispatch = useDispatch();
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(true);
  // const data = useSelector(
  //   (state) => state.growthRateForUser.getGrowthRateForUser.noramlizedResponse
  // );

  // const cycleIdOfDeathRateToState = useSelector(
  //   (state) => state.deathRateForUser.cycleIdOfDeathRateToState
  // );
  // const downloadingStatus = useSelector(
  //   (state) => state.growthRateForUser.getGrowthRateForUser.status
  // );
  // const updatingStatus = useSelector((state) => state.updating.growthRate);

  const defineMaxValueAndInterval = () => {
    if (data) {
      const margin = data.current?.length - data.planned?.length;
      const maxValue = margin > 0 ? data.current?.length : data.planned?.length;
      const interval = Math.round(maxValue / 6);
      const maxValueY =
        margin > 0
          ? data.current?.[data.current.length - 1][1]
          : data.planned?.[data.planned.length - 1][1];

      return {
        maxValue,
        interval,
        maxValueY,
      };
    } else {
      return {
        maxValue: null,
        interval: null,
        maxValueY: null,
      };
    }
  };

  const { maxValue, interval, maxValueY } = defineMaxValueAndInterval();

  useEffect(() => {
    // if (chosenCycle) {
    //   if (chosenCycle._id !== cycleIdOfDeathRateToState) {
        makeRequest();
    //   }
    // } else {
    //   dispatch(removeGrowthRateFromState());
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCycle]);

  const makeRequest = (isNow, isUpdating) => {
    if(!chosenCycle._id) {
      setLoader(false);
      return;
    }
    setLoader(true);
    api.dashboard.getGrowthRateForUser(chosenCycle._id).then((data) => {
      setData(data);
      setLoader(false);
    });
    // dispatch(
    //   getGrowthRateForUserByCycleIdThunk({
    //     chosenCycleId: chosenCycle._id,
    //     isUpdating,
    //   })
    // );
  };

  // useUpdate(makeRequest, 1);

  const eChartsOption = {
    tooltip: {
      trigger: "axis",
    },
    formatter: function (params) {
      let colorSpan = (color) =>
        '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
        color +
        '"></span>';
      let rez = "<p>" + params[0].axisValue + "</p>";
      params.forEach((item, index) => {
        let xx =
          '<p style="display:flex; justify-content:space-between">' +
          "<span>" +
          colorSpan(`#${curveColors[index]}`) +
          item.seriesName +
          " - " +
          "</span>" +
          "<span>" +
          "<strong>" +
          (item.data[1] === null ? 0 : item.data[1]) +
          "</strong>" +
          "</span>" +
          "</p>";
        rez += xx;
      });

      return rez;
    },
    grid: {
      left: "25",
      right: "5",
      bottom: "0",
      top: "10",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      name: t("DEATH_RATE.DAY"),
      nameLocation: "start",
      nameGap: -18,
      nameTextStyle: {
        color: "#414141",
        fontFamily: "Roboto-Regular",
        fontSize: ".75rem",
        verticalAlign: "top",
        lineHeight: "23",
      },
      min: 1,
      max: maxValue ? maxValue : 6,
      interval: interval ? interval : 1,
      axisTick: {
        show: false,
      },
      axisLabel: {
        showMinLabel: false,
        color: "#414141",
        fontFamily: "Roboto-Regular",
        fontSize: ".81rem",
      },
    },

    yAxis: {
      show: true,
      type: "value",

      name: t("DEATH_RATE.WEIGHT"),
      nameLocation: "center",
      nameGap: 40,
      nameTextStyle: {
        color: "#414141",
        fontFamily: "Roboto-Regular",
        fontSize: ".81rem",
        lineHeight: "23",
      },

      min: 0,
      max: maxValueY,
      splitNumber: 8,
      axisTick: {
        show: false,
      },
      axisLabel: {
        showMinLabel: false,
        color: "#414141",
        fontFamily: "Roboto-Regular",
        fontSize: ".88rem",
      },
    },
    series: [
      {
        name: t("CHART.PLANNED"),
        type: "line",
        smooth: true,
        symbol: `image://data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23${curveColors[0]}'/%3E%3C/svg%3E`,
        symbolSize: 7,
        lineStyle: {
          color: `#${curveColors[0]}`,
        },
        data: data?.planned,
      },
      {
        name: t("CHART.CURRENT"),
        type: "line",
        smooth: true,
        symbol: `image://data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23${curveColors[1]}'/%3E%3C/svg%3E`,
        symbolSize: 7,
        lineStyle: {
          color: `#${curveColors[1]}`,
        },
        data: data?.current,
      },
    ],
  };

  return loader ? (
    <CircularProgress />
  ) : (
    <ReactEChart option={eChartsOption} style={{ height: "158px" }} />
  );
}

export default GrowthChart;
