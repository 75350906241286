import styles from "./RoundSensorsPad.module.scss";
// import RoundSensor from "../roundSensor/RoundSensor";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

function RoundSensorsPad({
  areaName,
  firstData,
  secondData,
  coolingPed = false
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.label}>
          <div className={styles.name}>
          {t("SENSORS_DATA.AREA")} {areaName}
          </div>
          {coolingPed && <div className={styles.info}>
            {t("SENSORS_DATA.COOLING_PAD")}
          </div>}
        </div>
        <div className={styles.data}>
          <div className={styles.name}>Sensor {firstData?.position}</div>
          <div className={styles.id}>
            {firstData?.devices?.map(item => (<div key={item}>ID {item}</div>))}
          </div>
          <div className={styles.status}>
            <div className={styles.label}>
              Status
            </div>
            <div className={classNames(styles.val, {
              [styles.sensorError]: !firstData,
              [styles.sensorOn]: firstData?.status === "on",
              [styles.sensorOff]: firstData?.status === "off",
            })}>
              {firstData?.status === 'on' && 'On'}
              {firstData?.status === 'off' && 'Disconnected'}
            </div>
          </div>
        </div>
      </div>
        {/* <RoundSensor sensorNumber={secondSensorNumber} status={secondStatus} /> */}
    </>
  );
}

export default RoundSensorsPad;
