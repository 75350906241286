import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { injectStore } from "./app/api/makeRequest";
import Notification from "./app/components/notifications/Notifications";
import { injectStoreUser } from "./app/api/requests/userAuth";
import { injectStoreCompany } from "./app/api/requests/company";
import { injectStoreCoop } from "./app/api/requests/coopsForUsers";
import { injectStoreDevice } from "./app/api/requests/device";
import { injectStoreFarm } from "./app/api/requests/farmsForUsers";
injectStore(store);
injectStoreUser(store);
injectStoreCompany(store);
injectStoreCoop(store);
injectStoreDevice(store);
injectStoreFarm(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <Notification />
    </Provider>
  </BrowserRouter>
);
