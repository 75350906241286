import styles from "./style.module.scss";

function DefaultHeader() {
  return (
    <>
        <div className={styles.secondBlock}>
          
        </div>
        <div></div>
    </>
  );
}

export default DefaultHeader;
