import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGrowthDayToCycle } from "../../store/coopsForUserSlice";
import style from "./style.module.scss";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  addParametersToUrl,
  cleanParametersFromUrl,
  getUrlParameters,
} from "../../utils/urlUtils";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
dayjs.extend(utc);

function GrowthDayInput({ isDownloading = false }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cyclesArray = useSelector((state) => state.coopsForUser.cyclesArray);
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);

  const isToday = chosenCycle
    ? chosenCycle.maxGrowthDay === chosenCycle.settedGrowthDay &&
      chosenCycle.active
    : false;

  const [growthDay, setGrowthDay] = useState("");

  useEffect(() => {
    const currentDayInUrl = getUrlParameters()["day"];
    if (currentDayInUrl) {
      setGrowthDay(+currentDayInUrl);
      localStorage.setItem("SALUS_GROWTH_DAY", currentDayInUrl);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (chosenCycle) {
      setGrowthDay(chosenCycle.settedGrowthDay);
      localStorage.setItem("SALUS_GROWTH_DAY", chosenCycle.settedGrowthDay);

      if (!isToday) {
        addParametersToUrl([`day=${chosenCycle.settedGrowthDay}`]);
      } else {
        cleanParametersFromUrl();
      }
    } else {
      setGrowthDay("");
      localStorage.removeItem("SALUS_GROWTH_DAY")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCycle]);

  const handleInput = (event) => {
    if (!chosenCycle) return;

    if (
      (!isNaN(+event.target.value) &&
        +event.target.value <= chosenCycle.maxGrowthDay &&
        +event.target.value > 0) ||
      event.target.value === ""
    ) {
      setGrowthDay(event.target.value);
      return;
    }
  };

  const handleBlur = (event) => {
    if (chosenCycle) {
      localStorage.setItem("SALUS_GROWTH_DAY", event.target.value);
      dispatch(
        setGrowthDayToCycle({
          cyclesArray,
          chosenCycleId: chosenCycle._id,
          newValue: +event.target.value,
        })
      );
      if (!isToday) {
        addParametersToUrl([`day=${event.target.value}`]);
      } else {
        cleanParametersFromUrl();
      }
    }
  };
  // const makeRequest = (isNow, isUpdating) => {
  //   dispatch(
  //     getDeathRateForUserByCycleIdThunk({
  //       chosenCycleId: chosenCycle._id,
  //       isUpdating,
  //     })
  //   );
  // };

  return (
    <>
      <input
        disabled={!!isDownloading}
        type="text"
        value={growthDay}
        onInput={handleInput}
        onBlur={handleBlur}
        className={style.input}
      />
      
      <button
        type="button"
        // onClick={() => makeRequest()}
        className={classNames(style.headerButton, {
          [style.pressedButton]: chosenCycle.settedGrowthDay !== growthDay,
          [style.unpressedButton]: chosenCycle.settedGrowthDay === growthDay
        })}
      >
        {t("DEATH_RATE.UPDATE_BUTTON")}
      </button>
    </>
  );
}

export default GrowthDayInput;
