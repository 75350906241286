import { useEffect, useState } from "react";
import styles from "./ModalCreateUser.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InputModal from "../../UI/inputModal/Input";
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";
import { changeUpdatingReloadStatus } from "../../../store/reloadMarker";
import { openModalAddCompanys, openModalAddUser } from "../../../store/modal";
import { registrationUser } from "../../../api/requests/userAuth";
import Modal from "../Modal";

function ContentModal({isShow = false, setShow = () => {}}) {
  const { i18n, t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const { role, farms } = useSelector(
    (state) => state.auth.profileData.userData
  );

  const listCompany = useSelector(
    (state) => state.company.getCompanys
  );

  const modalCreateUser = useSelector((state) => state.modal.modalAddUser);

  const [data, setData] = useState({
    email: {
      val: "",
      required: true,
      error: false,
      roles: ['supervisor', 'admin', 'user']
    },
    role: {
      val:'',
      required: true,
      error: false,
      roles: ['supervisor', 'admin', 'user']
    },
    company: {
      val: '',
      roles: ['supervisor']
    },
    password: {
      val: '',
      required: true,
      error: false
    },
    phone: {
      val: ''
    },
    lastName: {
      val: ''
    },
    firstName: {
      val: ''
    },
    address: {
      val: ''
    },
    
  });
  
  const handleSave = () => {
    let req = {};
    let isError = false;
    for(let item of Object.keys(data)) {
      if(data[item].required) {
        if(!data[item].val) {
          setData((data) => {
            return {...data, [item]: {...data[item], error: true} };
          });

          if(!data[item]?.roles || data[item]?.roles.includes(role)) {
            isError = true;
          }

          // data[item].error = true;
        } else {
          setData((data) => {
            return {...data, [item]: {...data[item], error: false} };
          });
          // data[item].error = false;
          req[item] = data[item].val;
          if(item === 'farms') {
            req.farms = data[item].val._id;
          }
          
        }
      } else { 
        req[item] = data[item].val;
      }
      if(item === 'company') {
        req.company = data[item].val._id;
      }
    }
    if(isError) return;
    registrationUser(req).then((res) => {
      dispatch(changeUpdatingReloadStatus("start"));
      setShow(false);
    }).catch((err) =>{
    });
  }

  const handleData = (val, key) => {
    setData((obj) => {
      return {...obj, [key]: {...obj[key], val} };
    });
    
    if(key === 'role') {
      setSelectedRole({name:val});
    }
    if(key === 'company') {
      setSelectedCompany(val);
    }
  }

  useEffect(() => {
    if(role) {
      if(role === "supervisor") {
        setRoles([
          {
            _id: "supervisor",
            name:"supervisor"
          }, {
            _id: "admin",
            name: "admin"
          }, {
            _id: "user",
            name: "user"
          }, {
            _id: "viewer",
            name: "viewer"
          }
        ]);
      } else if(role === "admin") {
        setRoles([
          {
            _id: "user",
            name: "user"
          }, {
            _id: "viewer",
            name: "viewer"
          }
        ]);
      } else if(role === "user") {
        setRoles([
          {
            _id: "viewer",
            name: "viewer"
          }
        ]);
      }
    }
  }, [])

  // useEffect(() => {
  //   if(chosenCycle) {
  //     setChosenCoop(old => {
  //       return chosenFarm.coops.find(coop => coop._id === chosenCycle.coopId);  
  //     })
  //   } else {
  //     setChosenCoop(chosenFarm.coops[chosenFarm.coops.length - 1]);
  //   }
  // }, [chosenFarm]);

  // useEffect(() => {
  // }, [chosenCoop]);

  return (  
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.top}>
            <h3>New User</h3>
            <div className={styles.close} onClick={() => setShow(false)}>+</div>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.contentItem}>
            <div className={styles.label}>Role *</div>
            <Select styleType={data.role.error && 'error'} name="Roles" activeSelect={selectedRole}  placeholder="Role" list={roles} handleSelect={(e)=>{handleData(e.name, "role")}}/>
          </div>
          
          {["supervisor"].includes(role) && 
          <div className={styles.contentItem}>
            <div className={styles.label}>Company</div>
            <Select styleType={data.company.error && 'error'} name="Company" activeSelect={selectedCompany} placeholder="Company" list={listCompany} handleSelect={(e)=>{handleData(e, "company")}}/>
          </div>}
          
          <div className={styles.contentItem}>
            <div className={styles.label}>Email *</div>
            <InputModal styleType={data.email.error && 'error'} placeholder="mail@mail.com" name="" defaultValue="" onClick={(val) => handleData(val, "email")}/>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.label}>First Name</div>
            <InputModal placeholder="First Name" name="" defaultValue="" onClick={(val) => handleData(val, "firstName")}/>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.label}>Last Name</div>
            <InputModal placeholder="Last Name" name="" defaultValue=""  onClick={(val) => handleData(val, "lastName")}/>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.label}>Address</div>
            <InputModal placeholder="Address" name="" defaultValue=""  onClick={(val) => handleData(val, "address")}/>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.label}>Password *</div>
            <InputModal styleType={data.password.error && 'error'} placeholder="Password" name="" defaultValue=""  onClick={(val) => handleData(val, "password")}/>
          </div>
        </div>

        <div className={styles.buttons} onClick={handleSave}>
          <Button label={'Save'}/>
        </div>
      </div>
    </>
  );
}

function ModalCreateUser() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const modalCreateUser = useSelector((state) => state.modal.modalAddUser);

  const setShowModalAddUser = (val) => {
    dispatch(openModalAddUser({open: val}));
  };

  useEffect(() => {
    setShowModal(modalCreateUser.open);
  }, [modalCreateUser]);

  return (
    showModal && <>
      <Modal contentModal={<ContentModal isShow={showModal} setShow={setShowModalAddUser}/>}  setShow={setShowModalAddUser} />
    </>
  );
}

export default ModalCreateUser;
